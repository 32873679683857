import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0df615683b12787340a4dae18664c95d@o59538.ingest.us.sentry.io/4507136258342912",
  debug: false,
  environment: "development",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  sampleRate: 0.5,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
});